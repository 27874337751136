import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-no-sidebar',
  templateUrl: './no-sidebar.component.html',
  styleUrl: './no-sidebar.component.css',
  standalone: true,
  imports: [RouterModule],
})

export class NoSidebarComponent {

  constructor(){
  }
  
}
