import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AsideMenuComponent } from '../aside-menu';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.css',
  standalone: true,   
  imports: [
    CommonModule,
    RouterModule,
    AsideMenuComponent
  ],
})
export class SidebarComponent {

  constructor(){
  }
}
