import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AsideMenuComponent } from '@pages/layouts/aside-menu';
import { CommonModule } from '@angular/common';
import { NgMaterialModule } from '@shared/ng-material/ng-material.module';
import { LoaderService } from '@app/_services/loader.service';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core';



@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet, 
    AsideMenuComponent,
    CommonModule,
    NgMaterialModule
  ],

  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})

export class AppComponent {

  isLoading: boolean = false;

  constructor(
    private preloaderService: LoaderService, 
    private router: Router
  ) {
   
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.preloaderService.show();
      }
      if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
        this.preloaderService.hide();
      }
    });

    this.preloaderService.isLoading$.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
    
  }

}
