import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthService } from '@pages/auth/_services/auth.service';

@Injectable()
export class HttpJwtInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        console.log('intercept(url) : ', request.url);
        // add authorization header with jwt token if available
        let currentUser = this.authService.currentUser;
        console.log("every request....");
        if (currentUser && currentUser?.token) {
            console.log('JwtInterceptor : adding Authorization token.');
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser?.token}`
                },
                body: {
                    ...request.body
                }
            });
        }

        return next.handle(request);
    }
}