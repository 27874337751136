import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideZoneChangeDetection } from '@angular/core';
import {  provideClientHydration } from '@angular/platform-browser';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { routes } from './app.routes'; 
import { NgMaterialModule } from './_shared/ng-material/ng-material.module';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideJwtInterceptor  } from '@app/_interceptors/app.interceptors';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideClientHydration(),
    provideAnimations(),
    provideHttpClient(withFetch()),
    provideJwtInterceptor(),
    NgMaterialModule,
  ],
  
};