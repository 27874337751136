<header>
  <div class="container-fluid sidebar">
      <div class="row w-100 p-3">
          <div class="col-md-12 col-sm-12 col-12">
            <div class="_menu_sm">
              <div class="logo cursor-pointer text-center" (click)="onClickHome()">
                <img src="assets/images/logo.svg" alt="Logo" width="100" class="logo">
              </div>  
              <div class="menu-icon" (click)="openMenu()">
                  <img src="assets/images/menu.png" alt="Logo" width="30" class="menu">
              </div>
            </div>
            <div class="desktop_menu" [class.mobile_menu]="menuValue">
                  <ul>
                    
                      <li [ngClass]="{ 'active' : isHome}" (click)="onClickHome()">
                        <a>
                          <img src="assets/images/menu/home-page.svg" alt="Home Page" class="mx-3" height="25px">
                          <span>Home Page</span>
                        </a>
                      </li>
                      <li  [ngClass]="{ 'active' : isOrder}" (click)="onClickOrders()">
                        <a>
                          <img src="assets/images/menu/orders.svg" alt="Orders" class="mx-3" height="25px">
                          <span>Orders</span>
                        </a>
                      </li>
                      <li  [ngClass]="{ 'active' : isProduct}" (click)="onClickProducts()">
                        <a >
                          <img src="assets/images/menu/products.svg" alt="Products" class="mx-3" height="25px">
                          <span>Products</span>
                        </a>
                      </li>
                      <li   [ngClass]="{ 'active' : isTag}" (click)="onClickTags()">
                        <a>
                          <img src="assets/images/menu/tag.svg" alt="Tags" class="mx-3" height="25px">
                          <span>Tags</span>
                        </a>
                      </li>
                      <li   [ngClass]="{ 'active' : isClient}" (click)="onClickClients()">
                        <a >
                          <img src="assets/images/menu/client.svg" alt="Cient" class="mx-3" height="25px">
                          <span>Cient</span>            
                        </a>
                      </li>
                      <li   [ngClass]="{ 'active' : isClientFeedback}" (click)="onClickClientFeedback()">
                        <a>
                          <img src="assets/images/menu/client-feedback.svg" class="mx-3" alt="Client Feedback" height="25px">
                          <span>Client Feedback</span>
                        </a>
                      </li>
                      <li  [ngClass]="{ 'active' : isSetting}" (click)="onClickSettings()">
                        <a >
                          <img src="assets/images/menu/settings.svg" alt="Settings" class="mx-3" height="25px">
                          <span>Settings</span>
                        </a>
                      </li>
                      <li   [ngClass]="{ 'active' : isProfile}" (click)="onClickProfile()">
                        <a >
                          <img src="assets/images/menu/profile.svg" alt="Profile" class="mx-3" height="25px">
                          <span>Profile</span>
                        </a>
                      </li>
                      <li   [ngClass]="{ 'active' : isChangeLanguage}" (click)="onClickChangeLanguage()">
                        <a >
                          <img src="assets/images/menu/translate.svg" alt="Arabia" class="mx-3" height="25px">
                          <span>Arabia</span>
                        </a>
                      </li>
                      <li (click)="onClickLogout()">
                        <a>
                          <img src="assets/images/menu/log-out.svg" alt="Log out" class="mx-3" height="25px">
                          <span>Log out</span>
                        </a>
                      </li>
                  </ul>
              </div>
          </div>
      </div>
  </div>
</header>
