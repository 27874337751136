import { Routes } from '@angular/router';
import { NoSidebarComponent } from '@pages/layouts/no-sidebar';
import { SidebarComponent } from '@pages/layouts/sidebar';
import { ProfileComponent } from '@pages/profile';
import { SettingComponent } from '@pages/setting';
import { NotFoundComponent } from '@pages/layouts/404/404.component'
import { SplashScreenComponent }  from '@pages/auth/splash-screen';

export const routes: Routes = [
  
  {
    path: '',
    component: NoSidebarComponent, 
    children: [
      { path: '', component: SplashScreenComponent },
      {
        path: 'auth',
        loadChildren: () => import('./_pages/auth/auth.module').then(m => m.AuthModule)
      },
    ]
  },

  {
    path: '',
    component: SidebarComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./_pages/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'orders',
        loadChildren: () => import('./_pages/orders/orders.module').then(m => m.OrdersModule),
      },
      {
        path: 'tags',
        loadChildren: () => import('./_pages/tag/tag.module').then(m => m.TagModule),
      },
      {
        path: 'clients',
        loadChildren: () => import('./_pages/client/client.module').then(m => m.ClientModule),
      },
      {
        path: 'products',
        loadChildren: () => import('./_pages/product/product.module').then(m => m.ProductModule),
      },
      {
        path: 'settings',
        loadChildren: () => import('./_pages/setting/setting.module').then(m => m.SettingModule),
      },
 
    ]
  },

  { path: 'not-found', component: NotFoundComponent },
  { path: '**', redirectTo: 'not-found' }, 

];