import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AuthService } from '@pages/auth/_services';
import { BaseComponentClass } from '@app/_shared/_components/base-component/base-component-class';
import { SnackBarService } from '@app/_services';

@Component({
  selector: 'app-aside-menu',
  templateUrl: './aside-menu.component.html',
  styleUrl: './aside-menu.component.css',
  standalone: true,
  imports : [  
    CommonModule,
    RouterModule,
  ]
})

export class AsideMenuComponent   implements OnInit {

  menuValue:boolean=false;
  menu_icon :string ='bi bi-list';


  openMenu(){
    this.menuValue =! this.menuValue ;
    this.menu_icon = this.menuValue ? 'bi bi-x' : 'bi bi-list';
  }
  
   closeMenu() {
    this.menuValue = false;
    this.menu_icon = 'bi bi-list';
  }


  private sidebar: HTMLElement | null = null;
  
  isHome : boolean = false;
  isOrder : boolean = false;
  isProduct: boolean = false;
  isTag : boolean = false;
  isClient : boolean = false;
  isClientFeedback : boolean = false;
  isSetting : boolean = false;
  isProfile : boolean = false;
  isChangeLanguage : boolean = false;
  isSettings : boolean = false;

  constructor(
    private elRef: ElementRef,
    private router : Router,
    private authService : AuthService,
    private snackBarService : SnackBarService

  ) { 
  }

  ngOnInit(): void {
    this.sidebar = this.elRef.nativeElement.querySelector('#sidebar');
    this.sidebar!.classList.toggle('open');
  }


  resetMenuTabs(){
    this.isHome  = false;
    this.isOrder  = false;
    this.isProduct  = false;
    this.isTag  = false;
    this.isClient  = false;
    this.isClientFeedback  = false;
    this.isSetting  = false;
    this.isProfile  = false;
    this.isChangeLanguage  = false;
    this.isSettings = false;
    this.closeMenu();
  }

  onClickHome()
  {
    this.resetMenuTabs();
    this.isHome = true;
    this.router.navigate(['/dashboard']);

  }

  onClickOrders()
  {
    this.resetMenuTabs();
    this.isOrder = true;
    this.router.navigate(['/orders']);

  }

  onClickProducts()
  {
    this.resetMenuTabs();
    this.isProduct = true;
    this.router.navigate(['/products']);

  }
  onClickTags()
  {
    this.resetMenuTabs();
    this.isTag = true;
    this.router.navigate(['/tags']);

  }
  onClickClients()
  {
    this.resetMenuTabs();
    this.isClient = true;
    this.router.navigate(['/clients']);

  }
  onClickClientFeedback()
  {
    this.resetMenuTabs();
    this.isClientFeedback = true;
    this.router.navigate(['/clients/feedback']);

  }
  onClickSettings()
  {
    this.resetMenuTabs();
    this.isSetting = true;
    this.router.navigate(['/settings']);

  }
  
  onClickProfile()
  {
    this.resetMenuTabs();
    this.isProfile = true;
    this.router.navigate(['/settings/profile']);

  }

  onClickChangeLanguage()
  {
    this.resetMenuTabs();
    this.isChangeLanguage = true;
    this.router.navigate(['/orders']);

  }

  onClickLogout()
  {
     this.authService.signout()
     .subscribe({
      next: () => { 
        localStorage.removeItem('currentUser');
        this.snackBarService.openSuccessSnackBar('Your Successfully Sign out.');
        this.navigateToLogin();
      },
      error: (error: any) => {
        // this.handleApiErrors(error);
        this.snackBarService.openErrorSnackBar('Failed To Sign out!.');
        this.navigateToLogin();
      },
      complete: () => {
        console.info('SignOut.complete()');
        localStorage.removeItem('currentUser');
        this.navigateToLogin();
      }
    });
  }

  navigateToLogin(){
    this.router.navigate(['auth']);
  }

  

}